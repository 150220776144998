import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import Header from "./Header";

export default function App() {
  return (
    <div className="App">
      <Header />
      <div className="App-header">
        <a
          href="http://sterilissolutions.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Sterilis Solutions Site Survey
        </a>
      </div>
    </div>
  );
}
