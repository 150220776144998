import React from "react";
import "./App.css";
import logo from "./sterilis_logo.png";

function Header() {
  // Import result is the URL of your image
  return <img src={logo} alt="Logo" />;
}

export default Header;
